.mapContainer {
  position: relative; /* Ensure the container has relative positioning */
  display: flex;
}

.filterButtonsContainer {
  z-index: 1000; /* or any value that ensures it's above other elements */
  position: absolute; /* Ensure the position is absolute or fixed */
  margin-left: auto;
}

.filterButton {
  margin-right: 8px;
}

.sliderContainer {
  z-index: 1000; /* Ensure the slider is above other elements */
  margin-left: auto; /* This will push the slider to the right */
  margin-right: auto;
  max-width: calc(100% - 32px); /* Adjust the width as needed */
}