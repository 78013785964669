.image-container {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 300px;
  }
  
  .small-image {
    max-width: 100%;
    height: auto;
  }